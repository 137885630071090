export function camelCase(text) {
    if(text === 'ip') return 'IP';
    if(text === 'spf_status') return 'SPF Status';
    if(text === 'dmarc_status') return 'DMARC Status';
    const words = text.split("_");
    return words.map((word, index) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + ' ';
    }).join("");
}

export function showIssues(count) {
    if (count < 0 || count === undefined) {
        return "Invalid issue count";
    } else if (count === 0) {
        return "No new issues";
    } else if (count === 1) {
        return "1 new issue";
    } else {
        return `${count.toLocaleString()} new issues`;
    }
}

// per Rob 07-24-2024
export function categorizeScore(score) {
    if (score <= 200) {
        return "Very Low";
    } else if (score <= 400) {
        return "Low";
    } else if (score <= 600) {
        return "Medium";
    } else if (score <= 800) {
        return "High";
    }
    return "Critical";
}

// per Rob 07-24-2024
export function chartColor(value) {
    // Clamp the value between 0 and 100, then scale it to a range of 0 to 1000
    const score = Math.max(0, Math.min(100, value)) * 10;

    if (score <= 200) {
        return "#52c8e8"; // blue https://www.colorhexa.com/52c8e8
    } else if (score <= 400) {
        return "#76c505"; // green https://www.colorhexa.com/76c505
    } else if (score <= 600) {
        return "#ffcd42"; // yellow https://www.colorhexa.com/ffcd42
    } else if (score <= 800) {
        return "#fe7b07"; // orange https://www.colorhexa.com/fe7b07
    }
    return "#da1232"; // red https://www.colorhexa.com/da1232
}

export const bgColor = (value) => `style="background-color: ${chartColor(value)};"`;

export function formatDate(value) {
    try {
        const date = new Date(value);
        const formattedDate = new Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Explicitly set user's time zone
        }).format(date);

        return formattedDate;
    }
    catch (error) {
        return null;
    }
}

export function showDate(value) {
    const date = formatDate(value);
    if(date)
        return date;
    return formatDate(value + 'Z')
}

export function formatDateOnly(value) {
    try {
        const date = new Date(value);
        const formattedDate = new Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Explicitly set user's time zone
        }).format(date);

        return formattedDate;
    }
    catch (error) {
        return value;
    }
}

export function showDateOnly(value) {
    const date = formatDateOnly(value);
    if(date)
        return date;
    return formatDateOnly(value + 'Z')
}

export function showNow()
{
    const date = new Date();
    try {
        const formattedDate = new Intl.DateTimeFormat(navigator.language, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Explicitly set user's time zone
        }).format(date);
        return formattedDate;
    }
    catch (error) {
        return date.toString();
    }
}


export const showYesNo = (value) => value?'Yes':'No';
export const showPercent = (value) => value.toLocaleString() + '%';
export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
