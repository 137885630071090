import React, { useEffect, useState } from 'react';
import { BugIcon } from './components/BugIcon';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    Card,
    CardContent,
    Box,
    Alert
} from '@mui/material';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import restHandler from './apiClient';
import CustomAlert from './components/CustomAlert';
import { showDateOnly, showYesNo, showPercent } from './utils/helpers';
import { notice_error } from './utils/toast_helpers';
import Loading from './components/Loading';

const confirmLink = (event) => {
    const shouldNavigate = window.confirm("The link you clicked provides additional resources for fixing vulnerabilities and requires leaving this site. Do you want to continue?");
    if (!shouldNavigate) {
        event.preventDefault();
    }
};

const IntelCard = ({ title, value, urgent = false }) => (
    <Card sx={{ bgcolor: "grey.100", boxShadow: 1 }}>
        <CardContent>
        <CalendarMonthIcon sx={{ fontSize: 20, color: "primary.main" }} />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>{title}</Typography>
            <Typography variant="body1" sx={{ color: urgent ? "error.main" : "text.primary", fontWeight: urgent ? "bold" : "normal" }}>
                {value}
            </Typography>
        </CardContent>
    </Card>
);

const NvdInfo = ({ title, cve }) => (
    <Card sx={{ bgcolor: "grey.100", boxShadow: 1 }}>
        <CardContent>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>{title}</Typography>
            <Button
                onClick={confirmLink}
                href={`https://nvd.nist.gov/vuln/detail/${cve}`}
                target='_blank'
                rel='noopener noreferrer'
                variant="contained"
                color="primary"
                sx={{ mt: 1 }}
            >
                {cve}
            </Button>
        </CardContent>
    </Card>
);

const MsKbArticles = ({ title, articles }) => (
    <Box sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>{title}</Typography>
        <Grid container spacing={1} sx={{ mt: 1 }}>
            {Object.keys(articles).map((key) => (
                <Grid item key={key}>
                    <Button
                        onClick={confirmLink}
                        href={`https://support.microsoft.com/en-us/Search/results?query=${key}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        variant="contained"
                        color="primary"
                        sx={{ minWidth: "120px" }}
                    >
                        {key}
                    </Button>
                </Grid>
            ))}
        </Grid>
    </Box>
);

const VulnerabilityModal = ({ isOpen, onClose, cve }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            try {
                const result = await restHandler('GET', `/api/svintel/${cve}`);
                setData(result);
            } catch (err) {
                setError(err.detail || err);
                notice_error(err.message || err.detail || err);
            }
        };

        fetchData();
    }, [cve]);

    if (!isOpen) return null;
    if (error) return <CustomAlert type='error' message={error} />;
    if (!data) return <Loading />;

    const orpheus = data.orpheus;
    const alertSeverity = orpheus.exploited_in_wild ? "error" : "info";

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg" sx={{ zIndex: 1500 }}>
            <DialogTitle>
            <Typography variant="h5" sx={{ display: "flex", alignItems: "center" }}>
                🐞 {data.id}
            </Typography>
            </DialogTitle>
            <DialogContent dividers sx={{ maxHeight: "75vh", overflowY: "auto" }}>
                {/* Summary */}
                <Alert severity="warning" sx={{ mb: 2 }}>
                    <Typography variant="h6">Summary</Typography>
                    <Typography variant="body1">{data.description}</Typography>
                </Alert>

                {/* Exploitation Status */}
                <Alert severity={alertSeverity} sx={{ mb: 2 }}>
                    <Typography variant="h6"><strong>Exploitation Status</strong> - {orpheus.exploitation_status}</Typography>
                </Alert>

                {/* Intel Cards */}
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}><IntelCard title="Last Updated on" value={showDateOnly(orpheus.last_updated)} /></Grid>
                    <Grid item xs={6} sm={4}><IntelCard title="Risk Score" value={orpheus.sv_score} urgent={orpheus.sv_score >= 650} /></Grid>
                    <Grid item xs={6} sm={4}><IntelCard title="Ransomware" value={showYesNo(orpheus.ransomware_related)} urgent={orpheus.ransomware_related} /></Grid>
                    <Grid item xs={6} sm={4}><IntelCard title="Exploit in Wild" value={showYesNo(orpheus.exploited_in_wild)} urgent={orpheus.exploited_in_wild} /></Grid>
                    <Grid item xs={6} sm={4}><IntelCard title="Likelihood of Exploit" value={showPercent(orpheus.exploit_probablity)} urgent={orpheus.exploit_probablity > 50} /></Grid>
                    <Grid item xs={12}><NvdInfo title="NVD Information" cve={data.id} /></Grid>
                </Grid>

                {/* Microsoft KB Articles */}
                {data.ms_kb && (
                    <Box sx={{ mt: 3 }}>
                        <MsKbArticles title="Microsoft KB Articles" articles={data.ms_kb.articles} />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default VulnerabilityModal;