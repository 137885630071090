import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

const useAuth = () => {

    const { auth, ...rest } = useContext(AuthContext);
    return {
        auth,
        //isAllowedRole: (allowedRoles) => auth?.roles?.includes(allowedRoles),
        isAllowedRole: (allowedRoles) => 
            Array.isArray(allowedRoles)
                ? allowedRoles.some(role => auth?.roles?.includes(role)) // ✅ Works with multiple roles
                : auth?.roles?.includes(allowedRoles), // ✅ Works with a single role        
        isToken: auth?.accessToken || false,
        isAuthenticated: !!auth?.username,
        isAuthenticated: !(auth?.username === null),
        isAdmin: auth?.roles?.includes("admin"),
        isReseller: auth?.roles?.includes("reseller"),
        isEULA: auth?.eula ?? false,
        setAccepedEULA: (accepted) => {
            auth.eula = accepted ? Date() : false;
        },
        ...rest,
    };
};

export default useAuth;