import React, { useEffect } from "react";
import useAuth from "./hooks/useAuth";
import { Routes, Route } from 'react-router-dom';
import Modal from 'react-modal';
import { Container, CssBaseline, Box } from "@mui/material";
import Footer from "./Footer";
import { ROLES } from "./constants/roles";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { CompanyOnboard } from "./CompanyOnBoardForm";
import SvIntel from "./SvIntel";

import Layout from "./components/Layout";


import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';

import RequireAuth from './components/RequireAuth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
const Corrections = React.lazy(() => import('./Corrections/Corrections'));
const Company = React.lazy(() => import('./CompanyList'));
const Welcome = React.lazy(() => import('./Welcome/Welcome'));
const EULA = React.lazy(() => import('./Eula/EULA'));
const Login = React.lazy(() => import('./auth/Login'));
const Logout = React.lazy(() => import('./auth/Logout'));
const ForgotPasswordPage = React.lazy(() => import('./auth/ForgotPasswordPage'));
const ResetPasswordPage = React.lazy(() => import('./auth/ResetPasswordPage'));

const AdminUsers = React.lazy(() => import('./Admin/AdminUsers'));
const AdminCompanies = React.lazy(() => import('./Admin/AdminCompanies'));
const AdminAddCompany = React.lazy(() => import('./Admin/AdminAddCompany'));
const AdminResellers = React.lazy(() => import('./Admin/AdminResellers'));

// Set app element for accessibility (inside component)
Modal.setAppElement('#root'); // Replace with the ID of your app root

function App() {

  const { reloadKey } = useAuth();

  const onShowDashoard = () => {
    console.log("Show Dashboard");
  }

  useEffect(() => {
    // Prevent drag-to-refresh in Safari iOS
    const preventPullToRefresh = (e) => {
      // Only prevent default behavior if the scroll is at the top of the page
      if (window.scrollY === 0 && e.touches[0].clientY > e.touches[0].clientX) {
        e.preventDefault();
      }
    };
    // Attach the event listener
    document.addEventListener('touchmove', preventPullToRefresh, { passive: false });
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('touchmove', preventPullToRefresh);
    };
  }, []);

  return (
    <>
      <CssBaseline />
      <Box display="flex" flexDirection="column" minHeight="100vh" key={reloadKey}>
        <Container maxWidth="lg">
          <QueryClientProvider client={queryClient}>
            <ToastContainer />
            <Routes>
              <Route path="/" element={<Layout />}>
                {/* public routes */}
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Logout />} />
                <Route path="password-reset/:token" element={<ResetPasswordPage />} />
                <Route path="forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/admin/add-company" element={<AdminAddCompany />} />
                <Route path="/unauthorized" element={<Unauthorized />} />

                {/* USER: we want to protect these routes */}
                <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                  <Route path="/svintel" element={<SvIntel />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                  <Route path="/" element={<Company />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                  <Route path="/welcome" element={<Welcome force={true} onClose={onShowDashoard} />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                  <Route path="/eula" element={<EULA />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                  <Route path="/corrections" element={<Corrections />} />
                </Route>

                {/* ADMIN: we want to protect these routes */}
                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                  <Route path="/admin/resellers" element={<AdminResellers />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Reseller, ROLES.Admin]} />}>
                  <Route path="/admin/companies" element={<AdminCompanies />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Reseller, ROLES.Admin]} />}>
                  <Route path="/admin/users" element={<AdminUsers />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Reseller, ROLES.Admin]} />}>
                  <Route path="/admin/onboard" element={<CompanyOnboard />} />
                </Route>
                <Route path="*" element={<h1>404 - Page Not Found</h1>} /> {/* Catch-all for unknown routes */}
                {/* catch all */}
                <Route path="*" element={<Missing />} />
              </Route>
            </Routes>
          </QueryClientProvider>
        </Container>
        {/* Add Footer */}
        <Footer />
      </Box>
    </>);
}

export default App;
